<template>
  <div>
    <b-loading is-full-page :active.sync="isLoading">
      <b-icon
          pack="fas"
          icon="sync-alt"
          size="is-large"
          custom-class="fa-spin">
      </b-icon>
      <br>
      <h3 class="title">Recalculando</h3>
    </b-loading>
    <b-field grouped group-multiline v-if="printOrder">
      <p class="control">
        <button class="button field is-danger" @click="clearStudies"
                :disabled="!checkedRows.length">
          <b-icon icon="eraser"></b-icon>
        </button>
      </p>
      <p class="control">
        <button :disabled="!checkedRows.length" class="button" @click="onDeleteItems">
          <b-icon icon="trash"></b-icon>
        </button>
      </p>
      <p class="control">
        <select-study
            api-url="price-list"
            :company="company"
            :saved="saved"
            @selectStudy="onSelectStudy">

        </select-study>
      </p>
    </b-field>
    {{deliveryDays}}
    <b-table
        :checkable="printOrder"
        :checked-rows.sync="checkedRows"
        :data="studies"
        striped
        bordered
        narrowed
        detailed
        sticky-header
        custom-detail-row
        @details-open="onOpenDetail"
    >
      <b-table-column field="code" label="Código" v-slot="props">
        <study-configs :element="props.row" :order="data" :can-no-sample="printOrder"/>
      </b-table-column>
      <b-table-column field="name" label="Estudio" v-slot="props">
        {{ props.row.name }} {{!!alternativeNames[props.row.code] ? '-' : ''}} {{alternativeNames[props.row.code]}}
      </b-table-column>
      <b-table-column label="D. E." width="60" v-slot="props" >{{props.row.delivery_days_orig}}</b-table-column>
      <b-table-column label="Indicaciones" v-slot="props" width="40%">
          <b-collapse
              :open="false"
              position="is-bottom"
              :aria-id="`contentIdFor_${props.row.code}`"
              >
            <template #trigger="propsc">
              <a
                  :aria-controls="`contentIdFor_${props.row.code}`"
                  :aria-expanded="propsc.open">
                <span v-if="propsc.open">
                  Contraer
                </span>
                <span v-else>
                  {{ props.row.indications.toString().substring(0, 50) }} {{props.row.indications.toString().length > 50 ? '...' : ''}}
                </span>
              </a>
            </template>
            <p>
              {{ props.row.indications }}
            </p>
          </b-collapse>
      </b-table-column>
      <b-table-column field="price_show" label="Precio" width="40" v-slot="props" numeric>
        <b-progress v-if="priceLoader"></b-progress>
        <template v-else>
                  <span :class="{'has-text-danger	is-italic': props.row.discount !== 0}"
                        v-if="payInCash || coPay">
                     {{ props.row.price_show | currency }}
                </span>
          <span v-else>
          0.00
        </span>
        </template>
      </b-table-column>
      <template slot="detail" slot-scope="props">
        <tr v-for="item in props.row.items" :key="`${props.row.code}-${item.code}`">
          <td v-if="printOrder"></td>
          <td></td>
          <td>
            <b-collapse :open="false" aria-id="contentIdForA11y1" v-if="item.has_sub_studies">
              <div slot="trigger">
                <study-configs :element="item" :enable-click="true" @openDetail="onOpenDetail(item)" :order="data" :can-no-sample="printOrder"/>
              </div>
              <table v-if="!!item.items">
                <tr v-for="sub in item.items">
                  <td>
                    <b-collapse :open="false" aria-id="contentForSubStudies" v-if="sub.has_sub_studies">
                      <div  slot="trigger">
                        <study-configs :element="sub" :enable-click="true" @openDetail="onOpenDetail(sub)" :order="data" :can-no-sample="printOrder"/>
                      </div>
                      <table v-if="!!item.items">
                        <tr v-for="subSub in sub.items">
                          <td>
                            {{ subSub.name }} {{!!alternativeNames[subSub.code] ? '-' : ''}} {{alternativeNames[subSub.code]}}
                          </td>
                        </tr>
                      </table>
                    </b-collapse>
                    <span v-else>
                         <study-configs :element="sub" :order="data" :can-no-sample="printOrder"/>
                    </span>
                  </td>
                  <td>{{ sub.name }} {{!!alternativeNames[sub.code] ? '-' : ''}} {{alternativeNames[sub.code]}}</td>
                </tr>
              </table>
            </b-collapse>
            <span v-else>
              <study-configs :element="item"  :order="data" :can-no-sample="printOrder"/>
            </span>
          </td>
          <td>{{ item.name }} {{!!alternativeNames[item.code] ? '-' : ''}} {{alternativeNames[item.code]}}</td>
        </tr>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon
                  icon="box-open"
                  size="is-large">
              </b-icon>
            </p>
            <p>No hay estudios seleccionados.</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
import SelectStudy from "../study/SelectStudy";
import {mapState, mapGetters} from 'vuex';
import StudyConfigs from "../study/StudyConfigs.vue";

export default {
  name: "PosOrderStudy",
  components: {SelectStudy, StudyConfigs},
  props: {
    printOrder: Boolean,
    isShow: Boolean,
    data: Object,
    saved: Object
  },
  data() {
    return {
      // data: [],
      isLoading: false,
      checkedRows: [],
      columnsStudies: [
        {
          field: 'code',
          label: 'Código',
        },
        {
          field: 'name',
          label: 'Estudio',
        },
        {
          field: 'price',
          label: 'Precio',
        },
      ],
      apiUrl: 'studies'
    }
  },
  computed: {
    ...mapState('order', [
      'studies', 'company', 'payInCash', 'priceLoader', 'coPay', 'alternativeNames'
    ]),
    ...mapGetters('order', [
      'readOnly', 'priceList', 'hasStudies'
    ]),
    deliveryDays() {
      if(this.studies.length !== 0){
        return `Días hábiles de entrega en total : ${this.studies[0].delivery_days || ''} `;
      }
    }
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'order/setCompany') {
        this.changeCompany(this.company.price_list);
      }
      if (mutation.type === 'order/setPayInCash') {
        this.changeCompany(this.company.price_list);
      }
      if( mutation.type === 'order/setAgreement') {
        this.changeCompany(this.company.price_list);
      }
      if( mutation.type === 'order/setCopay') {
        this.changeCompany(this.company.price_list);
      }
    })
  },
  methods: {
    onDeleteItems() {
      let selectedStudies = this.studies.filter(el => !this.checkedRows.includes(el));
      this.$emit('update:data', selectedStudies);
      this.$store.dispatch('order/setStudies', selectedStudies);
      this.checkedRows = [];
      this.$emit('updateStudies', selectedStudies);
    },
    async onSelectStudy(data) {
      let studies = this.studies;
      if(! studies.find(el => el.code === data.code)) {
        this.$store.dispatch('order/addStudy', data);
        this.changeCompany();
        this.changeTaxes(data);
      } else {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: `Es estudio <b>${data.name}</b> ya está <b>agregado</b>.`,
          type: 'is-danger',
          hasIcon: true,
          ariaModal: true
        })
      }
    },
    changeCompany() {
     if (this.hasStudies) {
       this.$store.state.order.priceLoader = true;
       setTimeout(async () => {
         if (!this.isShow) {
           if (!this.readOnly) {
             let studies = this.studies.map(el => el.code);
             if (studies.length) {
               this.$store.state.order.priceLoader = true;
               await this.$http.post('filter-studies', {
                 studies,
                 price_list: this.priceList,
                 sub_branch_office: this.$store.state.order.subBranchOffice,
                 pay_in_cash: this.$store.state.order.payInCash,
                 co_pay: this.$store.state.order.coPay,
                 agreement: this.$store.state.order.agreement,
                 company: this.$store.state.order.company.company || ''
               }).then(({data}) => {
                 let filterStudies = data.data || [];
                 this.$emit('update:data', filterStudies);
                 //this.$store.dispatch('order/updatePrices', filterStudies);
                 this.$store.dispatch('order/setStudies', filterStudies);
                 this.$emit('updateStudies', filterStudies);
               }, errors => {
                 this.$toast.error('Sin Datos');
               }).finally(() => {
                 if(!this.readOnly){
                   const studiesData = this.studies.map(item => {return {code: item.code}});
                   if(studiesData.length){
                     this.$http.post('/studies/delivery-date', {
                       studies: studiesData
                     }).then(({data}) => {
                        this.$emit('updateDeliveryDate', data.delivery_date);
                     })
                   }
                 }
               });
               this.$store.state.order.priceLoader = false;
             }
           } else {
             this.$store.state.order.priceLoader = false;
           }
         } else {
           this.$store.state.order.priceLoader = false;
         }
       }, 1000);
     }
    },
    async onOpenDetail(row) {
      let items = row.items || [];
      if (items.length < 1) {
        this.$loader.show();
        await this.getData(row.code).then(({data}) => {
          row.items = data.sub_studies;
        });
        this.$loader.hide();
      }
    },
    clearStudies() {
      this.checkedRows = [];
      this.$store.dispatch('order/setStudies', []);
      this.$emit('updateStudies', selectedStudies);
    },
    changeTaxes(study){
      //verificar si el estudio no requiere iva
      if(!!study.no_tax){
        //mandar alerta
        this.$buefy.dialog.alert({
          title: 'Producto sin IVA',
          message: 'Le recordamos que este producto no incluye IVA y no puede ir en una misma orden con otros productos que si lo incluyan, a menos que se indique lo contrario.',
          confirmText: 'Aceptar',
          onConfirm: () => {
            this.checkVaccineCovid(study);
          }
        });
        //desactivar el order store del iva
        this.$store.dispatch('order/setWithIva', false);
      }else{
        this.checkVaccineCovid(study);
      }
    },
    checkVaccineCovid(study){
      if(!!study.require_data_vaccine_covid){
        this.$buefy.dialog.alert({
          title: 'Datos requeridos para la vacuna',
          message: "Le recordamos que este producto requiere que se capturen los siguientes datos: <br> No. Lote <br> Datos en campos extra ",
          confirmText: 'Aceptar'
        });
      }
    }
  }
}
</script>

<style scoped>

</style>
