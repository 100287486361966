<template>
  <b-field :label="label"
           :label-position="labelPosition"
  style="min-width: 320px;">
    <b-autocomplete
        :data="data"
        :readonly="readonly"
        :placeholder="placeholder"
        :field="primaryField"
        :loading="isFetching"
        @typing="getAsyncData"
        @input="onInput"
        @blur="onBlur"
        ref="select_company"
        v-model="localValue"
        @select="onSelect"
        :disabled="disabled"
        :clearable="clearable"
        autofocus>

      <template slot-scope="props">
        <div class="media">
          <div class="media-left">
            <img width="32" :src="props.option.logo" alt="Logo">
          </div>
          <div class="media-content">
            {{ props.option.name }}
            <br>
            <small>
              {{ $t('company.attributes.company') }} {{ props.option.company }}<br>
              {{ $t('common.attributes.email') }} <b>{{ props.option.email }}</b>
            </small>
          </div>
        </div>
      </template>
    </b-autocomplete>

  </b-field>
</template>

<script>
import debounce from 'lodash/debounce';

export default {
  name: "SelectCompany",
  props: {
    readonly: Boolean,
    label: {
      type: String,
      default() {
        return this.$t('company.attributes.company')
      }
    },
    placeholder: {
      type: String,
      default() {
        return this.$t('company.placeholders.company')
      }
    },
    primaryKey: {
      type: String,
      default: 'id'
    },
    primaryField: {
      type: String,
      default: 'name'
    },
    value: {},
    company: String|Number|Object,
    labelPosition: String,
    disabled: Boolean,
    clearable: Boolean,
    saved: {
      type: Object,
      default () {
        return {
          studies: false,
          company: false
        }
      }
    },
    type: {
      type: String,
      default: 'order'
    },
  },

  data() {
    return {
      data: [],
      selected: null,
      isFetching: false,
      apiUrl: 'companies',
      params: {
        page: 1,
        perPage: 20,
        q: ''
      },
      localValue: ''
    }
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'order/setCompany') {
        let company = state.order.company || {};
        this.localValue = company[this.primaryField] || '';
      }
      if (mutation.type === 'order/clearOrder' && !this.saved.company) {
        this.localValue = '';
      }
      if(mutation.type === 'quotation/setCompany') {
        let company = state.quotation.company || {};
        this.localValue = company[this.primaryField] || '';
      }
      if (mutation.type === 'quotation/clearQuotation'){
        this.localValue = '';
      }
      if(mutation.type === 'medicalspecialties/setCompany') {
        let company = state.medicalspecialties.company || {};
        this.localValue = company[this.primaryField] || '';
      }
      if (mutation.type === 'medicalspecialties/clearMedicalSpecialties') {
        this.localValue = '';
      }
      if(mutation.type === 'order/setPatient') {
        let company = state.order.company;
        const patient = state.order.patient;
        const patientLapifan = patient && patient.lapifan_active && patient.lapifan_active.status;
        if(false && company.only_with_lapifan && !patientLapifan){
          this.$store.dispatch('order/setCompany', {});
          this.localValue = '';
          this.data = [];
        }
      }
    });
  },
  methods: {
    // You have to install and import debounce to use it,
    // it's not mandatory though.
    getAsyncData: debounce(function (name) {
      this.data = [];
      if (!name.length) {
        this.data = []
        return
      }
      this.isFetching = true
      this.params.q = name
      const patient = this.$store.state.order.patient;

      this.getAll(this.params)
          .then(({ data = [] }) => {
            this.data = []
            data.forEach((item) => {
              let canAdd = true;
              if(item.only_with_lapifan && !(patient && patient.lapifan_active && patient.lapifan_active.status)){
             //   canAdd = false;
              }
              if(canAdd){
                this.data.push(item)
              }
            })
          })
          .catch((error) => {
            this.data = []
            throw error
          })
          .finally(() => {
            this.isFetching = false
          })
    }, 500),
    onSelect(data = null) {
      if (data === null) {
        return
      }
      if(!data.company){return ;}
      this.$http.get(`/companiesblock/${data.company}`).then(response => {
        const result = response.data.data;
        if (result.IBloqueoPorFaltaDePago.toString() === "1") {
          const graceDate = result.FechaGraciaBloqueo === '0001-01-01' || result.FechaGraciaBloqueo === '0000-00-00' ?  '' : `La fecha de gracia es: <strong>${result.FechaGraciaBloqueo}</strong>`
          this.$buefy.dialog.alert({
            title: 'Empresa bloqueada',
            message: `Empresa bloqueada por falta de pago. ${graceDate}`,
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => {
              this.selected = null;
              this.$emit('select', null);
              this.$emit('update:company', null);
            }
          });
        }else{
          this.selected = data;
          this.$emit('select', data);
          this.$emit('update:company');
          switch (this.type) {
            case 'search':
              break;
            case 'quotation':
              this.$store.dispatch('quotation/setCompany', data);
              break;
            case 'medicalspecialties':
              this.$store.dispatch('medicalspecialties/setCompany', data);
              break;
            case 'order':
            default:
              this.$store.dispatch('order/setCompany', data);
              this.$store.dispatch('order/setStudies', []);
              break;
          }
        }
      })
    },
    onInput(val) {
      if (val === '') {
        this.onSelect(val);
      }
    },
    onBlur() {
      setTimeout(() => {
        if (!this.selected) {
          this.localValue = '';
        }
      }, 600);
    }
  }
}
</script>

<style scoped>

</style>
